import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import CardOasis from '../components/CardOasis'

class OasisIndex extends React.Component {
  render() {
    const site = get(this, 'props.data.site.siteMetadata')
    const oasis = get(this, 'props.data.oasis.posts')

    const tabCardOasis = []
    oasis.forEach((data, i) => {
      tabCardOasis.push(
          <LazyLoad height={1} offset={500} once={true} key={i}>
            <CardOasis data={data.post} site={site} isIndex={true} key={i} />
          </LazyLoad>
        )
    })


    return (
      <Layout location={this.props.location}>
        <Helmet
          title={get(site, 'title')}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: `@${get(site, 'twitter')}` },
            { property: 'og:title', content: get(site, 'title') },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: get(site, 'description') },
            { property: 'og:url', content: get(site, 'url') },
            {
              property: 'og:image',
              content: `${get(site, 'url')}/img/logo.png`,
            },
          ]}
        />
        <div className="oasis">
          <div className="container">
            <div className="row">
              <div className="col-md-3 text-right"><h1 className="underlined">Les Oasis à financer</h1></div>
              <div className="col-md-9">
                <h2>Les projets à soutenir par la Coopérative</h2>
                <div className="desc-page-oasis">
                  Cette page présente les oasis partenaires de la Coopérative et qui souhaitent bénéficier ou ont bénéficié des prêts liés à l'épargne citoyenne.
                  Au fur et à mesure, vous retrouverez sur cette page à la fois celles en demande d'un prêt et celles qui ont déjà été financées.<br/><br/>
                  Si vous souhaitez soutenir ces projets, <Link to="/participer">découvrez comment participer en cliquant ici</Link>.<br /><br />
                </div>
              </div>
            </div>
            <div className="grid">
              {tabCardOasis}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default OasisIndex

export const pageQuery = graphql`
  query OasisQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    oasis: allMarkdownRemark(
      filter: {
        frontmatter:{
          type:{eq : "oasis"}
        }
      },
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }) {
      posts: edges {
        post: node {
          fileAbsolutePath
          html
          excerpt(pruneLength: 300)
          frontmatter {
            layout
            title
            path
            avancement
            montant
            departement
            image {
              childImageSharp {
                resize(width: 600) {
                  src
                }
              }
            }
            description
          }
        }
      }
    }
  }
`
